import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, Grid, Typography, Card, CardContent, Box, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import services from './apiServices'; // Ensure this imports your services module
import SmallProductImage from './smallproductimg';

const UserPurchaseHistory = () => {
    const [orders, setOrders] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const lve_tp = await services.purchasehistory();
                if (lve_tp && Array.isArray(lve_tp.orderRows)) {
                    const groupedOrders = lve_tp.orderRows.reduce((acc, order) => {
                        const orderId = order.GNRTD_ORD_NO;
                        if (!acc[orderId]) acc[orderId] = { items: [], totalAmount: 0, totalQuantity: 0 };

                        acc[orderId].items.push(order);
                        acc[orderId].totalAmount = parseFloat(order.ORDR_TOT_GROS_AMT);
                        acc[orderId].totalQuantity = parseInt(order.ORDR_DLVRY_ITM_CNT);

                        return acc;
                    }, {});
                    setOrders(groupedOrders);
                } else {
                    console.error("Unexpected data format:", lve_tp);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setError("Failed to fetch purchase history.");
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    const handleViewDetails = (orderId) => {
        setSelectedOrder(orders[orderId]);
    };

    const handleCloseDialog = () => {
        setSelectedOrder(null);
    };

    const handleDownloadInvoice = async (orderId) => {
        const orderDetails = orders[orderId].items[0];
        const customerDetails = {
            name: orderDetails.USR_DISPLAY_NAME,
            email: orderDetails.USR_EMAIL,
            address: `${orderDetails.ADD_LN1}, ${orderDetails.ADD_LN2}, ${orderDetails.ADD_LN3}, ${orderDetails.ADD_CT}, ${orderDetails.ADD_PIN_ZIP}`
        };

        const productDetails = orders[orderId].items.map(product => ({
            name: product.PROD_DESP,
            price: parseFloat(product.ORDR_ORGNL_RATE),
            quantity: product.ORDR_QTY
        }));

        try {
            const response = await services.downloadInvoice({
                GNRTD_ORD_NO: orderId,
                customerDetails,
                productDetails
            });

            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, '_blank');
        } catch (error) {
            console.error('Error generating invoice:', error);
            alert('There was an issue generating the invoice.');
        }
    };

    const renderOrderHistory = () => (
        <Grid container spacing={4}>
            {Object.entries(orders).map(([orderId, orderData]) => (
                <Grid item key={orderId} xs={12} md={6}>
                    <Card sx={{ maxWidth: 345, height: '321px' }}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Order ID: {orderId}
                            </Typography>
                            {orderData.items.slice(0, 2).map((order, index) => (
                                <Box key={index}>
                                    <Typography variant="body2" color="textSecondary">Product: {order.PROD_DESP}</Typography>
                                </Box>
                            ))}
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                                Total Items: {orderData.totalQuantity}
                            </Typography>
                            <Button variant="outlined" size="small" onClick={() => handleViewDetails(orderId)}>
                                View Details
                            </Button>
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                                Total Order Amount: ₹{orderData.totalAmount.toFixed(2)}
                            </Typography>
                            <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={() => handleDownloadInvoice(orderId)}>
                                Download Invoice
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );

    if (loading) {
        return (
            <Container sx={{ textAlign: 'center', mt: 5 }}>
                <CircularProgress />
                <Typography variant="h6" sx={{ mt: 2 }}>Loading your purchase history...</Typography>
            </Container>
        );
    }

    if (error) {
        return (
            <Container sx={{ textAlign: 'center', mt: 5 }}>
                <Typography variant="h6" color="error">{error}</Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                    Please check your internet connection or try again later.
                </Typography>
            </Container>
        );
    }

    return (
        <Container sx={{ mt: 5 }}>
            <Typography variant="h4" gutterBottom>Your Purchase History</Typography>
            {Object.keys(orders).length === 0 ? (
                <Typography variant="h6" color="textSecondary" sx={{ mt: 3 }}>
                    You have not placed any orders yet.
                </Typography>
            ) : (
                renderOrderHistory()
            )}
            <Dialog open={Boolean(selectedOrder)} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Order Details</Typography>
                        <Button onClick={handleCloseDialog} color="primary">
                            Close
                        </Button>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {selectedOrder && (
                        <Box sx={{ mt: 2, borderTop: '1px solid #ccc', pt: 2 }}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="body2">Order ID: {selectedOrder.items[0].GNRTD_ORD_NO}</Typography>
                                <Typography variant="body2">Order Date: {new Date(selectedOrder.items[0].ORDR_DATE).toLocaleDateString()}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
                                <Typography variant="body2">Order Invoice Number: {selectedOrder.items[0].ORDR_S2H_INV_NO}</Typography>
                                <Typography variant="body2">Invoice Date: {new Date(selectedOrder.items[0].USR_REF_INV_DT).toLocaleDateString()}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
                                <Typography variant="body2">Status: {selectedOrder.items[0].ORDR_STTS}</Typography>
                            </Box>
                        </Box>
                    )}
                    {selectedOrder && selectedOrder.items.map((item, index) => (
                        <React.Fragment key={index}>
                            <Box sx={{ mt: 2, borderTop: '1px solid #ccc', pt: 2 }} />
                            <Card sx={{ mb: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <SmallProductImage imagePath={item.PROD_IMG_URL} alt={item.PROD_DESP} />
                                <CardContent sx={{ flex: 1 }}>
                                    <Typography variant="body2">Product: {item.PROD_DESP}</Typography>
                                    <Typography variant="body2">Amount: {item.ORDR_ORGNL_RATE}</Typography>
                                    <Typography variant="body2">Quantity: {item.ORDR_QTY}</Typography>
                                    <Typography variant="body2">UOM: {item.PROD_UOM}</Typography>
                                </CardContent>
                            </Card>
                        </React.Fragment>
                    ))}
                    {selectedOrder && (
                        <Box sx={{ mt: 2, borderTop: '1px solid #ccc', pt: 2 }}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="body2">Total Items: {selectedOrder.totalQuantity}</Typography>
                                <Typography variant="body2" >
                                    Total Order Amount: ₹{selectedOrder.totalAmount.toFixed(2)}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
                                <Typography variant="body2">Order Payment Mode: {selectedOrder.items[0].ORDR_PYMT_MOD}</Typography>
                                <Typography variant="body2">Payment Date: {new Date(selectedOrder.items[0].ORDR_PYMT_DT).toLocaleDateString()}</Typography>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default UserPurchaseHistory;
